<template>

  <div class="header-show">
    <div>
      <el-steps :active="1" finish-status="success" align-center>
        <el-step :title="$t('ApplyAfterSales.basicInformation')"/>
        <el-step :title="$t('ApplyAfterSales.malfunctionInformation')"/>
      </el-steps>
    </div>

    <!-- 按钮和时间区域 -->
    <div class="buttons-and-time">
      <!-- 按钮区域 -->
      <div class="action-buttons">

        <div class="faultAndExcelButton">
          <el-button class="button-background-color" @click="showFaultDialog">
            {{ $t('ApplyAfterSales.fillMalfunctionInformation') }}
          </el-button>
          <el-button class="button-background-color" @click="showExcelImportDialog">
            {{ $t('ApplyAfterSales.importFromExcel') }}
          </el-button>
          <el-button class="button-background-color" @click="showFaultDialog">{{ $t('ApplyAfterSales.addNewRow') }}
          </el-button>
        </div>

        <el-dialog v-model="excelImportDialogVisible" style="text-align: left"
                   @open="disableScroll" @close="enableScroll"
                   :title="$t('ApplyAfterSales.import')" width="20%">
          <hr class="top-separator"/>

          <div class="dialog-content">

            <el-upload class="upload-excel-container"
                       ref="upload" drag
                       @change="handleFileChange"
                       action="#"
                       :limit="1"
                       accept=".xls,.xlsx"
            >
              <div class="upload-icon-wrapper">
                <el-icon class="el-icon--upload">
                  <upload-filled/>
                </el-icon>
              </div>
              <div class="upload-text-wrapper">
                {{ $t('ApplyAfterSales.clickOrDrag') }}
              </div>
            </el-upload>
            <!-- 显示文件名的位置 -->
            <div v-if="uploadedExcelFile" class="uploaded-file-name">
              {{ $t('ApplyAfterSales.uploadedFileName') }}：{{ uploadedExcelFile.name }}
            </div>
            <div class="download-text">
              <p> {{ $t('ApplyAfterSales.support') }} &nbsp;{{ $t('ApplyAfterSales.excelFormat') }}</p>
            </div>
          </div>

          <hr class="dialog-separator"/>

          <div class="dialog-footer">
            <el-button class="button-background-color" @click="downloadTemplate">
              {{ $t('ApplyAfterSales.downloadTemplate') }}
            </el-button>
            <el-button class="button-background-color" @click="confirmImport"> {{ $t('ApplyAfterSales.ok') }}
            </el-button>
          </div>
        </el-dialog>

      </div>

      <div class="action-buttons">
        <div class="button-group">
          <el-button class="button-background-color" @click="showImportResultDialog">
            {{ $t('ApplyAfterSales.importResult') }}
          </el-button>
          <el-button class="button-background-color" @click="refreshTableData">{{ $t('ApplyAfterSales.refresh') }}
          </el-button>
        </div>
        <!-- 导入结果弹框 -->
        <el-dialog :title="$t('ApplyAfterSales.importResult')" v-model="importResultDialogVisible" width="1500"
                   style="text-align: left"
                   @open="disableScroll" @close="enableScroll"
                   :before-close="handleCloseImportResultDialog">
          <!-- 下拉框 -->
          <el-select v-model="selectedStatus" :placeholder="$t('ApplyAfterSales.taskStatusPlaceholder')" clearable
                     filterable @change="handleStatusChange(selectedStatus)">
            <el-option :label="$t('ApplyAfterSales.notExecuted')" value="1"></el-option>
            <el-option :label="$t('ApplyAfterSales.executionSuccess')" value="3"></el-option>
            <el-option :label="$t('ApplyAfterSales.failToExecute')" value="2"></el-option>
            <el-option :label="$t('ApplyAfterSales.partialSuccess')" value="4"></el-option>
          </el-select>

          <!-- 表格 -->
          <el-table :header-cell-style="{background:'rgb(250, 250, 250)'}" :data="importData"
                    :row-style="{ height: '65px' }"
                    style="width: 100%;margin-top: 20px;">
            <el-table-column prop="taskStatus" :label="$t('ApplyAfterSales.taskStatus')">
              <template v-slot="scope">
                {{ scope.row.taskStatus in statusMap ? statusMap[scope.row.taskStatus] : scope.row.taskStatus }}
              </template>
            </el-table-column>

            <el-table-column prop="fileName" :label="$t('ApplyAfterSales.filename')"></el-table-column>
            <el-table-column prop="successRows" :label="$t('ApplyAfterSales.successfulRowNumbera')"></el-table-column>
            <el-table-column prop="failureRows" :label="$t('ApplyAfterSales.failedRowNumber')"></el-table-column>
            <el-table-column prop="totalRows" :label="$t('ApplyAfterSales.totalRowNumber')"></el-table-column>
            <el-table-column prop="operationTime" :label=" $t('ApplyAfterSales.operationTime')"></el-table-column>
            <el-table-column :label="$t('ApplyAfterSales.operation')">
              <template v-slot="scope">
                <el-button link @click="handleViewDetails(scope.row)">
                  <span class="link-text-style">{{ $t('ApplyAfterSales.failureRecord') }}</span>
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页 -->
          <PaginationBar :total-records="totalRecords" :current-page="currentPage" :page-size="pageSize"
                         @page-change="handlePageChange" @page-size-change="handlePageSizeChange"/>

        </el-dialog>

        <!-- 失败记录弹框 -->
        <el-dialog :title="$t('ApplyAfterSales.failureRecord')" v-model="FailDialogVisible" width="1500"
                   style="text-align: left"
                   @open="disableScroll" @close="enableScroll"
                   :before-close="handleFailDialogClose">
          <!-- 表格 -->
          <el-table :header-cell-style="{background:'rgb(250, 250, 250)'}" :data="failData"
                    :row-style="{ height: '65px' }"
                    style="width: 100%;margin-top: 20px;">
            <el-table-column prop="row" :label="$t('ApplyAfterSales.lineNumber')"></el-table-column>
            <el-table-column prop="message" :label="$t('ApplyAfterSales.failureReason')"></el-table-column>
          </el-table>
          <!-- 分页 -->
          <PaginationBar :total-records="failTotalRecords" :current-page="failCurrentPage" :page-size="failPageSize"
                         @page-change="failHandlePageChange" @page-size-change="failHandlePageSizeChange"/>
        </el-dialog>

      </div>
    </div>

    <!-- 故障信息弹框 -->
    <el-dialog v-model="faultDialogVisible"
               :title="$t('ApplyAfterSales.new')"
               style="text-align: left;width: 1200px;"
               @open="disableScroll" @close="handleDialogClose">
      <el-form ref="form" :model="formData" :rules="formRules" label-position="top">
        <el-row :gutter="20">
          <el-col :span="12">

            <el-form-item :label=" $t('ApplyAfterSales.materialCode')" prop="materialCode">
              <template #label>
                <span>{{ $t('ApplyAfterSales.materialCode') }}</span>
                <el-tooltip effect="dark">
                  <template v-slot:content>
                    <img :src="snMaterialCodeUrl" alt="Tooltip Image" class="resized-image">
                  </template>
                  <el-icon class="custom-tooltip-icon">
                    <QuestionIcon/>
                  </el-icon>
                </el-tooltip>
              </template>
              <el-input v-model="formData.materialCode" :placeholder="$t('ApplyAfterSales.materialCodePlaceholder')"
                        class="custom-input-width"
                        :rules="formRules.materialCode"></el-input>
            </el-form-item>

          </el-col>

          <el-col :span="12">
            <el-form-item label="SN:" prop="SN">
              <template #label>
                <span>SN</span>
                <el-tooltip effect="dark">
                  <template v-slot:content>
                    <img :src="snImgUrl" alt="Tooltip Image" class="resized-image">
                  </template>
                  <el-icon class="custom-tooltip-icon">
                    <QuestionIcon/>
                  </el-icon>
                </el-tooltip>
              </template>
              <el-input v-model="formData.SN" :placeholder="$t('ApplyAfterSales.sNPlaceholder')"
                        class="custom-input-width"
                        :rules="formRules.SN"></el-input>
            </el-form-item>

          </el-col>
        </el-row>

        <el-row :gutter="20">

          <el-col :span="12">
            <el-form-item label="SN Label:" prop="SNFileList" :rules="formRules.SNFileList">
              <el-upload v-model:file-list="formData.SNFileList" class="upload-demo"
                         :limit="1" accept="image/*"
                         :before-upload="handleBeforeUploadSN">
                <el-button class="upload-button">
                  <el-icon>
                    <Upload/>
                  </el-icon>
                  {{ $t('ApplyAfterSales.UploadAttachment') }}
                </el-button>
              </el-upload>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item :label=" $t('ApplyAfterSales.invoice')" prop="invoiceFileList">
              <el-upload v-model:file-list="formData.invoiceFileList" class="upload-demo"
                         :before-upload="handleBeforeUploadInvoice"
                         :limit="1" accept="image/*">
                <el-button class="upload-button">
                  <el-icon>
                    <Upload/>
                  </el-icon>
                  {{ $t('ApplyAfterSales.UploadAttachment') }}
                </el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">

          <el-col :span="12">
            <el-form-item :label="$t('ApplyAfterSales.defectiveCategory')" prop="defectCategory">
              <el-tree-select
                  v-model="formData.defectCategory"
                  :data="defectCategoryOptions"
                  :props="{ label: 'label', children: 'children' }"
                  :show-checkbox="false"
                  :placeholder="$t('ApplyAfterSales.defectiveCategoryPlaceholder')"
                  :render-after-expand="false"
                  clearable filterable
                  class="custom-input-width"
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">

            <el-form-item :label=" $t('ApplyAfterSales.motorAppearance')" prop="motorAppearance"
                          class="custom-radio-group"
                          :rules="formRules.motorAppearance">
              <el-radio-group v-model="formData.motorAppearance">
                <el-radio label="1"> {{ $t('ApplyAfterSales.good') }}</el-radio>
                <el-radio label="0"> {{ $t('ApplyAfterSales.abnormal') }}</el-radio>
              </el-radio-group>
            </el-form-item>

          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label=" $t('ApplyAfterSales.defectivePicture')" prop="imageFileList">
              <el-upload v-model:file-list="formData.imageFileList" class="upload-demo"
                         :on-change="handleChangeImage"
                         :before-upload="handleBeforeUploadImg"
                         :limit="1" accept="image/*">
                <el-button class="upload-button">
                  <el-icon>
                    <Upload/>
                  </el-icon>
                  {{ $t('ApplyAfterSales.UploadImage') }}
                </el-button>

              </el-upload>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item :label=" $t('ApplyAfterSales.defectiveVideo')" prop="videoFileList">

              <el-upload v-model:file-list="formData.videoFileList"
                         class="upload-demo"
                         :on-change="handleChangeVideo"
                         :before-upload="handleBeforeUploadVideo"
                         :limit="1" accept="video/*"
              >
                <el-button class="upload-button">
                  <el-icon>
                    <Upload/>
                  </el-icon>
                  {{ $t('ApplyAfterSales.UploadAttachment') }}
                </el-button>
              </el-upload>

            </el-form-item>
          </el-col>

        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="$t('ApplyAfterSales.defectiveDescription')" prop="defectDescription">
              <el-input type="textarea" v-model="formData.defectDescription" :rules="formRules.defectDescription"
                        rows="2"
                        :placeholder="$t('ApplyAfterSales.defectiveDescriptionPlaceholder')" clearable></el-input>
            </el-form-item>

          </el-col>
        </el-row>


        <!-- 类似地，继续添加更多字段的 el-row 和 el-col -->

        <!-- 按钮区域 -->
        <div class="dialog-footer">
          <el-button @click="closeFaultDialog" class="custom-reset-button">{{
              $t('ApplyAfterSales.cancel')
            }}
          </el-button>
          <el-button class="button-background-color" @click="validateBeforeSave"> {{ $t('ApplyAfterSales.ok') }}
          </el-button>
        </div>
        <!-- </div> -->
      </el-form>

    </el-dialog>

    <!-- 最新保存时间 -->
    <div class="save-time">
      {{ $t('ApplyAfterSales.lastSavedTime') }}: {{ currentTime }}
    </div>

  </div>
</template>

<script>
// 导入组件
import {ElMessage} from 'element-plus';
import PaginationBar from '@/components/PaginationBar.vue';
import {
  addAfterSalesDetails,
  //getDefectCategorysData,
  jobDetailError,
  loadImportData,
  uploadExcelFile,
  uploadFileData,
} from "@/api/api";
import {Upload, UploadFilled} from "@element-plus/icons-vue";
import dialogMixin from "@/mixins/dialogMixin";
import QuestionIcon from "@/components/QuestionIcon.vue";

export default {
  components: {
    QuestionIcon,
    UploadFilled,
    Upload,
    PaginationBar,
  },
  props: {
    loadData: {
      type: Function,
      required: true,
    },
    id: {
      type: String, // 或者根据您的实际情况设置适当的类型
      required: true
    }
  },
  data() {
    return {
      snMaterialCodeUrl: require('@/assets/sn-materialcode.png'),
      snImgUrl: require('@/assets/sn.jpg'),
      currentTime: '', // 用于存储动态时间
      faultDialogVisible: false, // 控制故障信息弹框显示/隐藏
      formData: {
        materialCode: '',
        SN: '',
        SNFileList: [], // 用于存储上传的文件列表
        SNUploadedFile: '', // 用于存储上传的文件列表
        invoiceFileList: [],
        invoiceUploadedFile: '', // 用于存储单个文件标识符
        defectCategory: '', // 存储选择的不良类别
        motorAppearance: '',
        imageFileList: [],
        imgUploadedFile: '',
        videoFileList: [],
        videoUploadedFile: '',
        defectDescription: '',

      },
      formRules: {
        SN: [{required: true, message: this.$t('ApplyAfterSales.enterSN'), trigger: 'blur'}],
        SNFileList: [
          {required: true, message: this.$t('ApplyAfterSales.uploadSNLabel'), trigger: 'blur'}
        ],
        motorAppearance: [{required: true, message: this.$t('ApplyAfterSales.selectMotorAppearance'), trigger: 'blur'}],
        defectDescription: [{
          required: true,
          message: this.$t('ApplyAfterSales.enterDefectDescription'),
          trigger: 'blur'
        }],
        // 其他字段的验证规则...
      },
      cascaderProps: {
        value: 'value', // 值字段
        label: 'label', // 显示的文本字段
        children: 'children' // 子选项字段
      },
      defectCategoryOptions: [],
      excelImportDialogVisible: false,
      uploadedFiles: [], // 存储已上传的文件
      importError: '', // 用于显示错误提示
      importResultDialogVisible: false,
      selectedStatus: '', // 下拉框选项
      importData: [], // 表格数据
      currentPage: 1, // 当前页数
      pageSize: 10, // 每页显示数量
      totalRecords: 0,

      failCurrentPage: 1, // 当前页数
      failPageSize: 10, // 每页显示数量
      failTotalRecords: 0,
      currentRowId: null, // 定义 currentRowId 参数

      FailDialogVisible: false,
      failData: [],

      selectWidth: 'auto', // 默认宽度或你的选择
      //所有上传的action
      uploadFile: '',
      uploadVideoFileList: '',
      uploadImageFileList: '',
      uploadInvoiceFileList: '',
      uploadSNFileList: '',

      fileUploaded: false,
      showFaultDialogAdd: false,
      isSearching: false,
      uploadedExcelFile: null, // 存储已上传文件的文件名
      statusMap: {
        '1': this.$t('ApplyAfterSales.notExecuted'),
        '3': this.$t('ApplyAfterSales.executionSuccess'),
        '2': this.$t('ApplyAfterSales.failToExecute'),
        '4': this.$t('ApplyAfterSales.partialSuccess'),
      },
    };
  },
  watch: {
    '$i18n.locale'() {
      this.fetchDefectCategoryOptions();
    }
  },
  created() {
    // 获取中国上海时区的当前时间并设置为currentTime的值
    const options = {timeZone: 'Asia/Shanghai'};
    this.currentTime = new Date().toLocaleString('zh-CN', options);
  },
  mixins: [dialogMixin],
  mounted() {
    this.fetchDefectCategoryOptions();
  },
  // 组件逻辑
  methods: {

    async failHandlePageChange(newPage) {
      try {
        this.failCurrentPage = newPage;
        await this.loadFailData(); // 调用加载数据的方法
      } catch (error) {
        console.error(this.$t('ProductManage.loadDataFailed'));
      }
    },

// 处理每页显示条数变化事件
    async failHandlePageSizeChange(newPageSize) {
      try {
        this.failPageSize = typeof newPageSize === 'string' ? parseInt(newPageSize, 10) : newPageSize;
        this.failCurrentPage = 1; // 重置为第一页
        await this.loadFailData(); // 调用加载数据的方法
      } catch (error) {
        console.error(this.$t('ProductManage.loadDataFailed'));
      }
    },

    async loadFailData() {
      try {
        const response = await jobDetailError(this.failCurrentPage, this.failPageSize, {"job_detail_id-eq": this.currentRowId});

        if (Array.isArray(response.data)) {
          this.failData = response.data; // 假设后端返回的数据直接赋值给 failData
        } else {
          this.failData = []; // 如果不是数组，则初始化为空数组
        }
        this.failTotalRecords = response.meta.total;
        this.failCurrentPage = response.meta.current_page;
        this.failPageSize = response.meta.per_page;
      } catch (error) {
        this.$message.error('Failed to load failure details');
      }
    },

    async handleViewDetails(row) {
      try {
        this.currentRowId = row.id; // 保存当前行的ID
        await this.loadFailData(); // 调用加载数据的方法
        this.FailDialogVisible = true; // 显示弹框
      } catch (error) {
        this.$message.error('Failed to load failure details');
      }
    },

    handleFileChange(file) {
      this.uploadedExcelFile = file.raw;
    },
    downloadTemplate() {
      const link = document.createElement('a');
      link.href = 'https://link-public-1310393141.cos.ap-shanghai.myqcloud.com/template/SNImportTemplate.xlsx';
      link.download = this.$t('ApplyAfterSales.materialSnImportTemplate'); // 可选，指定下载文件的名称
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async handleUpload() {
      // 确保上传的文件存在
      if (!this.uploadedExcelFile) {
        this.$message.error('Please select a file to upload.');
        return;
      }
      // 调用公用文件大小检查函数
      if (!this.checkFileSize.call(this, this.uploadedExcelFile)) {
        this.$message.error('文件大小不符合');
        return false; // 阻止上传
      }

      const formData = new FormData();
      formData.append('file', this.uploadedExcelFile);
      // 添加额外的参数
      formData.append('after_sale_id', this.id);
      try {
        const response = await uploadExcelFile(formData);
        // 上传成功，根据后端返回的数据 response 做相应的处理
        if (response.code === 0) {
          this.$message.success('File uploaded successfully.');
          await this.loadData();
        } else {
          this.$message.error('Failed to upload file.');
        }
      } catch (error) {
        this.$message.error('Failed to upload file.');
      } finally {
        this.excelImportDialogVisible = false;
      }
      this.excelImportDialogVisible = false;
    },
    async confirmImport() {
      // 在用户点击确定按钮后执行上传操作
      await this.handleUpload();
    },

    handleDialogClose() {
      this.faultDialogVisible = false;
      this.enableScroll();
    },
    showFaultDialog() {
      this.faultDialogVisible = true; // 点击按钮显示弹框
    },
    closeFaultDialog() {
      this.$refs.form.resetFields();
      this.faultDialogVisible = false; // 取消按钮关闭弹框
    },
    //新增故障信息，点击确定按钮触发的方法
    validateBeforeSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveFaultData();
        } else {
          this.$message.error(this.$t('ApplyAfterSales.formValidationFailed'));
        }
      });
    },
    async handleBeforeUploadSN(file) {
      // 调用公用文件大小检查函数
      if (!this.checkFileSize.call(this, file)) {
        return false; // 阻止上传
      }
      try {
        // 例如使用 axios 手动上传文件
        const formData = new FormData();
        formData.append('file', file);
        const response = await uploadFileData(formData);
        if (response.code === 0) {
          // 处理成功的响应
          this.$message.success(this.$t('ApplyAfterSales.uploadSuccess'));
          const fileData = response;
          this.formData.SNFileList.push({
            name: fileData.fileName, // 文件名
            key: fileData.key, // 文件名
            url: fileData.url // 文件访问URL
          });
          // 更新用于提交给后端的 uploadedFile 字符串
          this.formData.SNUploadedFile = fileData.key; // 假设 response.key 是文件标识符
        } else {
          // 处理非200的响应
          this.$message.error(this.$t('ApplyAfterSales.uploadFailed'));
        }
      } catch (error) {
        // 处理异常
        this.$message.error(this.$t('ApplyAfterSales.uploadException'));
      }
      return false;
    },
    async handleBeforeUploadInvoice(file) {
      // 调用公用文件大小检查函数
      if (!this.checkFileSize.call(this, file)) {
        return false; // 阻止上传
      }
      try {
        // 例如使用 axios 手动上传文件
        const formData = new FormData();
        formData.append('file', file);
        const response = await uploadFileData(formData);

        if (response.code === 0) {
          // 处理成功的响应
          this.$message.success(this.$t('ApplyAfterSales.uploadSuccess'));
          // 假设后端响应包含文件的URL和名称
          const fileData = response;
          this.formData.invoiceFileList.push({
            name: fileData.fileName, // 文件名
            key: fileData.key,
            url: fileData.url // 文件访问URL
          });
          // 更新用于提交给后端的 uploadedFile 字符串
          this.formData.invoiceUploadedFile = fileData.key; // 假设 response.key 是文件标识符
        } else {
          // 处理非200的响应
          this.$message.error(this.$t('ApplyAfterSales.uploadFailed'));
        }
      } catch (error) {
        // 处理异常
        this.$message.error(this.$t('ApplyAfterSales.uploadException'));
      }
      return false; // 阻止文件上传
    },
    async handleBeforeUploadImg(file) {
      // 调用公用文件大小检查函数
      if (!this.checkFileSize.call(this, file)) {
        return false; // 阻止上传
      }
      try {
        // 例如使用 axios 手动上传文件
        const formData = new FormData();
        formData.append('file', file);
        const response = await uploadFileData(formData);
        if (response.code === 0) {
          // 处理成功的响应
          this.$message.success(this.$t('ApplyAfterSales.uploadSuccess'));
          const fileData = response;
          this.formData.imageFileList.push({
            name: fileData.fileName, // 文件名
            key: fileData.key,
            url: fileData.url // 文件访问URL
          });
          // 更新用于提交给后端的 uploadedFile 字符串
          this.formData.imgUploadedFile = fileData.key; // 假设 response.key 是文件标识符
        } else {
          // 处理非200的响应
          this.$message.error(this.$t('ApplyAfterSales.uploadFailed'));
        }
      } catch (error) {
        // 处理异常
        this.$message.error(this.$t('ApplyAfterSales.uploadException'));
      }
      return false; // 阻止文件上传
    },
    async handleBeforeUploadVideo(file) {
      // 调用公用文件大小检查函数
      if (!this.checkFileSize.call(this, file)) {
        return false; // 阻止上传
      }
      try {
        // 例如使用 axios 手动上传文件
        const formData = new FormData();
        formData.append('file', file);

        const response = await uploadFileData(formData);
        if (response.code === 0) {
          // 处理成功的响应
          this.$message.success(this.$t('ApplyAfterSales.uploadSuccess'));
          // 假设后端响应包含文件的URL和名称
          const fileData = response;
          this.formData.videoFileList.push({
            name: fileData.fileName, // 文件名
            key: fileData.key,
            url: fileData.url // 文件访问URL
          });
          // 更新用于提交给后端的 uploadedFile 字符串
          this.formData.videoUploadedFile = fileData.key; // 假设 response.key 是文件标识符
        } else {
          // 处理非200的响应
          this.$message.error(this.$t('ApplyAfterSales.uploadFailed'));
        }
      } catch (error) {
        // 处理异常
        this.$message.error(this.$t('ApplyAfterSales.uploadException'));
      }
      return false; // 阻止文件上传
    },
    async saveFaultData() {
      //判断电机外观单选按钮是否为空
      if (!this.formData.motorAppearance) {
        this.$message.error(this.$t('ApplyAfterSales.selectMotorAppearance'));
        return;
      }

      // 数据验证通过，发送请求保存数据
      try {
        let searchCondition = {
          material_code: this.formData.materialCode,
          sn: this.formData.SN,
          sn_label: this.formData.SNUploadedFile,
          sn_label_name: this.formData.SNFileList.length > 0 ? this.formData.SNFileList[0].name : '',
          invoice: this.formData.invoiceUploadedFile,
          invoice_name: this.formData.invoiceFileList.length > 0 ? this.formData.invoiceFileList[0].name : '',
          error_type: this.formData.defectCategory,
          appearance_status: this.formData.motorAppearance,
          image: this.formData.imgUploadedFile,
          image_name: this.formData.imageFileList.length > 0 ? this.formData.imageFileList[0].name : '',
          video: this.formData.videoUploadedFile,
          video_name: this.formData.videoFileList.length > 0 ? this.formData.videoFileList[0].name : '',
          description: this.formData.defectDescription,
          after_sale_id: this.id,
        };

        await addAfterSalesDetails(searchCondition);

        this.faultDialogVisible = false;
        this.formData = {
          materialCode: '',
          SN: '',
          SNFileList: [], // 用于存储上传的文件列表
          invoiceFileList: [],
          defectCategory: '', // 存储选择的不良类别
          motorAppearance: '',
          imageFileList: [],
          videoFileList: [],
          defectDescription: '',
        };
        await this.loadData();
        // 在这里可以进行其他的提示或跳转等操作
        ElMessage.success(this.$t('ApplyAfterSales.saveSuccess'));

      } catch (error) {
        // 请求过程中发生错误，可以在这里处理错误情况
        this.$message.error(this.$t('ApplyAfterSales.saveFailed'));
      }
    },

    handleChange() {

    },
    showExcelImportDialog() {
      this.excelImportDialogVisible = true;
    },
    // 打开导入结果弹框
    async showImportResultDialog() {
      // 可以在这里根据需要获取表格数据等
      this.importResultDialogVisible = true;
      await this.loadImportData();
    },

    // 关闭导入结果弹框
    handleCloseImportResultDialog() {
      this.importResultDialogVisible = false;
      // 清空数据等操作
    },

    async handlePageChange(newPage) {
      // 异步操作的处理逻辑
      try {
        this.currentPage = newPage;
        // 根据新的页码发送请求获取数据，更新tableData
        if (this.isSearching) {
          await this.handleStatusChange();
        } else {
          await this.loadImportData();
        }
      } catch (error) {
        console.error(this.$t('ProductManage.loadDataFailed'));
      }
    },
    // 处理每页显示条数变化事件
    async handlePageSizeChange(newPageSize) {
      try {
        this.pageSize = typeof newPageSize === 'string' ? parseInt(newPageSize, 10) : newPageSize;
        // 根据新的页码发送请求获取数据，更新tableData
        if (this.isSearching) {
          await this.handleStatusChange();
        } else {
          await this.loadImportData();
        }
      } catch (error) {
        console.error(this.$t('ProductManage.loadDataFailed'));
      }
    },

    async handleStatusChange(newStatus) {
      try {
        this.currentPage = 1; // 重置分页
        this.selectedStatus = newStatus; // 更新选中状态
        await this.loadImportData();
      } catch (error) {
        console.error(this.$t('ProductManage.loadDataFailed'));
      }
    },

    async loadImportData() {
      try {
        let response;

        const queryParams = {
          "after_sale_id-eq": this.id
        };

        if (this.selectedStatus) {
          queryParams["status-eq"] = this.selectedStatus;
        }

        response = await loadImportData(this.currentPage, this.pageSize, queryParams);

        // 确保 response.data 存在且不为空数组
        if (response.data && Array.isArray(response.data) && response.data.length > 0) {

          this.importData = response.data.map(item => {
            // 使用try-catch来处理JSON.parse可能抛出的异常
            try {
              let content = JSON.parse(item.content); // 假设item.content是一个JSON字符串
              return {
                id: item.id,
                taskStatus: item.status, // 从解析后的content对象中获取status
                fileName: content.fileName, // 如果content中没有fileName，则使用item中的fileName
                successRows: content.success, // 同样处理success属性
                failureRows: content.total - content.success, // 确保不会因为未定义的值而导致NaN
                totalRows: content.total, // 同样处理total属性
                operationTime: item.created_at,
              };
            } catch (error) {
              // 返回一个带有默认值的对象或者null，取决于您的需求
              return {
                id: item.id,
                taskStatus: null,
                fileName: null,
                successRows: 0,
                failureRows: 0,
                totalRows: 0,
                operationTime: item.created_at,
                error: 'Content parsing failed'
              };
            }
          });
        } else {
          // 处理没有数据的情况
          this.importData = []; // 或者其他默认值
        }

        this.totalRecords = response.meta.total;
        this.currentPage = response.meta.current_page;
        this.pageSize = typeof response.meta.per_page === 'string' ? parseInt(response.meta.per_page, 10) : response.meta.per_page;
      } catch (error) {
        console.error(this.$t('ProductManage.loadDataFailed'));
      }
    },

    refreshTableData() {
      this.$emit('refresh-table-data');
    },

    async fetchDefectCategoryOptions() {
      try {
        const treeData = this.transformToTree(this.defectCategorysData());

        this.defectCategoryOptions = this.transformDefectCateforysData(treeData);
      } catch (error) {
        console.error(this.$t('ApplyAfterSales.fetchDefectCategoriesFailed'));
      }
    },

    //关闭弹框
    handleFailDialogClose(done) {
      // 清空数据
      this.failData = [];
      done();
    },

  },
};
</script>

<style scoped>

:deep(.el-upload-list) {
  width: 500px;
}

:deep(.el-upload-list__item-name) {
  width: 300px;
}

.upload-excel-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* 如果需要垂直居中 */
  justify-content: center; /* 如果需要水平居中 */
  /* 其他样式，如背景色、边框等 */
  border: 1px solid #ccc; /* 示例边框 */
  padding: 10px; /* 示例内边距 */
}

.upload-icon-wrapper {
  /* 图标容器的样式 */
  font-size: 100px; /* 图标大小 */
}

.upload-text-wrapper {
  /* 文字容器的样式 */
  margin-top: 10px; /* 图标和文字之间的间距 */
  /* 其他样式，如字体大小、颜色等 */
}

:deep(.el-upload-list__item-name) {
  max-width: 300px; /* 限制文件名的最大宽度 */
}

/* 去除弹框内容的内边距 */
:deep( .el-dialog__body ) {
  padding-bottom: 0; /* 或者您想要的较小的值 */
}

/* 如果您还想调整弹框的顶部、左侧或右侧内边距 */
:deep( .el-dialog__body) {
  padding: 20px 15px 5px 20px; /* 顶部、左右侧保持 20px 内边距，底部为 0 */
}

/* 如果 .el-dialog 有额外的样式需要覆盖 */
:deep( .el-dialog ) {
  padding: 0; /* 如果您可以控制弹窗的内边距，将其设置为0 */
}

.upload-excel-button {
  margin-left: -15px;
  margin-right: -15px;
}

:deep( .el-upload-dragger:hover ) {
  border-color: rgb(0, 154, 68); /* 鼠标悬停时的边框颜色 */
  border-style: dashed; /* 边框样式 */
  border-width: 1px; /* 边框宽度 */
}

/* 调整虚线的样式，使其更短 */
:deep( .el-upload-dragger ) {
  width: 100%; /* 这将使上传框尽可能地宽 */
  height: auto; /* 高度根据内容自动调整 */
  padding: 10px; /* 控制内边距以更好地对齐文本 */
  border: 1px dashed rgb(217, 217, 217);
  border-radius: 2px;
  display: flex; /* 使用flex布局来对齐子元素 */
  flex-direction: column; /* 将布局方向改为列 */
  justify-content: center; /* 水平居中对齐 */
  align-items: center; /* 垂直居中对齐 */
  background-color: rgb(250, 250, 250); /* 设置上传区域的背景色 */
}

:deep( .el-upload__text ) {
  text-align: center; /* 文本居中 */
  margin-top: 10px; /* 在图标和文字之间增加一些上边距 */
  margin-left: 0; /* 移除左边距 */
  font-size: 16px; /* 调整文字大小 */
}

.download-text {
  text-align: left;
  margin-top: 10px; /* 调整为所需的值 */
}

:deep( .custom-radio-group .el-radio__input.is-checked .el-radio__inner ) {
  border-color: rgb(0, 154, 68) !important;
  background-color: white !important;
}

:deep(.custom-radio-group .el-radio__input.is-checked .el-radio__inner::after ) {
  background-color: rgb(0, 154, 68) !important;
  width: 8px; /* 增加中间点的宽度 */
  height: 8px; /* 增加中间点的高度 */
  transform: translate(-50%, -50%); /* 确保点居中 */
}

:deep( .custom-radio-group .el-radio__label ) {
  color: rgb(38, 38, 38); /* 黑色 */
}

.custom-tooltip-icon {
  margin-left: 8px; /* 在图标和标签文本之间添加一些空间 */
  cursor: pointer; /* 更明确地指示这是一个可以互动的图标 */
  font-size: 14px;
  color: black;
}

:deep( .el-form-item__content ) {
  margin-top: 0; /* 减少内容顶部的外边距 */
}

:deep( .el-form-item__label ) {
  margin-bottom: 0; /* 减少label下方的外边距 */
  padding-bottom: 0; /* 减少label的内边距 */
}

.custom-input-width {
  width: 87%; /* 或其他您希望的宽度 */
}

.faultAndExcelButton {
  float: left;
}

.button-group {
  display: flex;
  gap: 10px;
  /* 两个按钮之间的间隔 */
  float: right;
}

.header-show {
  /* 样式 */
  flex-direction: column;
  /* 垂直布局 */
  align-items: center;
  padding: 20px;
  color: #333;
  /* 文字颜色 */
  margin-bottom: 20px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  /* 水平居中对齐 */
}

.step {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.circle-checkmark,
.circle-green {
  /* 样式 */
  background-color: #4CAF50;
  /* 绿色背景 */
  color: #fff;
  /* 白色文字 */
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: bold;
  font-size: 18px;
}

.line {
  /* 样式 */
  background-color: #4CAF50;
  /* 绿色背景 */
  height: 3px;
  flex-grow: 1;
  /* 填充剩余空间，使其变长 */
}

.step-text {
  /* 样式 */
  font-weight: bold;
  font-size: 16px;
}

.buttons-and-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* 确保父容器占满全宽 */
}

.action-buttons {
  /* 样式 */
  justify-content: space-between;
  /* 使按钮分散排列 */
  width: 100%;
  /* 让按钮占满父容器的宽度 */
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.save-time {
  text-align: left; /* 确保文本左对齐 */
  order: -1; /* 在 flex 容器中将它移到最前面 */
  width: 100%; /* 确保它占据整行的宽度 */
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* 设置字体系列 */
}

/* 样式，省略部分样式 */
.dialog-content {
  padding: 20px;
}

.form-item {
  margin-bottom: 10px;
}

</style>
