<template>
  <div class="table-show">
    <el-table :header-cell-style="{background:'rgb(250, 250, 250)'}" :data="tableData" style="width: 100%"
              :row-style="{ height: '65px' }" v-loading="loading" element-loading-text="Loading...">
      <el-table-column prop="materialCode" :label="$t('ApplyAfterSales.materialCode')"></el-table-column>
      <el-table-column prop="SN" label="SN"></el-table-column>
      <el-table-column prop="SNLabel" label="SN Label">
        <template v-slot="scope">
          <el-button v-if="scope.row.SNLabel" link @click="showSNLabel(scope.row)"><span
              class="link-text-style">{{ $t('ApplyAfterSales.view') }}</span></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="invoice" :label="$t('ApplyAfterSales.invoice')">
        <template v-slot="scope">
          <el-button v-if="scope.row.invoice" link @click="showInvoice(scope.row)"><span
              class="link-text-style">{{ $t('ApplyAfterSales.view') }}</span></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="defectCategory" :label="$t('ApplyAfterSales.defectiveCategory')"
                       :formatter="formatDefectCategory"></el-table-column>

      <el-table-column :label="$t('ApplyAfterSales.defectivePicture')" prop="defectImages" :width="200">
        <template v-slot="scope">
          <el-button v-if="scope.row.defectImages" link @click="showDefectImage(scope.row)"><span
              class="link-text-style">{{ $t('ApplyAfterSales.view') }}</span></el-button>
        </template>
      </el-table-column>

      <el-table-column prop="defectVideos" :label="$t('ApplyAfterSales.defectiveVideo')">
        <template v-slot="scope">
          <el-button v-if="scope.row.defectVideos" link @click="showVideo(scope.row)"><span
              class="link-text-style">{{ $t('ApplyAfterSales.view') }}</span></el-button>
        </template>
      </el-table-column>

      <el-table-column prop="motorAppearance" :label="$t('ApplyAfterSales.motorAppearance')" :width="200"
                       :formatter="formatMotorAppearance"></el-table-column>
      <el-table-column prop="defectDescription" :label="$t('ApplyAfterSales.defectiveDescription')" :width="200"></el-table-column>
      <el-table-column :label="$t('ApplyAfterSales.operation')" v-if="showOperationColumn">
        <template v-slot="scope">
          <el-button link @click="editItem(scope.row)"><span
              class="link-text-style">{{ $t('ApplyAfterSales.edit') }}</span></el-button>

          <el-popconfirm :title="$t('ApplyAfterSales.WhetherToDelete')" placement="top" @confirm="deleteItem(scope.row)"
                         :confirm-button-text="$t('ApplyAfterSales.ok')"
                         :cancel-button-text="$t('ApplyAfterSales.cancel')"
                         confirm-button-type="success"
                         width="250"
          >
            <template #reference>
              <el-button link><span class="link-text-delete">{{ $t('ApplyAfterSales.delete') }}</span></el-button>
            </template>
          </el-popconfirm>

        </template>
      </el-table-column>
      <template v-slot:empty>
        <EmptySlot/>
      </template>
    </el-table>

    <!-- 弹窗，用于展示视频 -->
    <el-dialog v-model="videoDialogVisible" @open="disableScroll" @close="enableScroll">
      <video v-if="currentVideoUrl" ref="videoPlayer" controls autoplay style="max-width: 100%;"
      >
        <source :src="currentVideoUrl" type="video/mp4">
        {{ $t('ApplyAfterSales.browserNotSupportVideoTag') }}
      </video>
    </el-dialog>


    <el-dialog v-model="showImageDialog" @open="disableScroll" @close="enableScroll">
      <img :src="imageUrl" alt="SN Label" style="max-width: 100%; max-height: 100%;">
    </el-dialog>

    <el-dialog v-model="invoiceVisible" width="30%">
      <img :src="invoiceUrl" alt="SN Label" style="max-width: 100%; max-height: 100%;">
    </el-dialog>

    <el-dialog v-model="defectImageVisible" width="30%">
      <img :src="defectImageUrl" alt="SN Label" style="max-width: 100%; max-height: 100%;">
    </el-dialog>

    <!-- 故障信息编辑弹框 -->
    <el-dialog v-model="faultDialogVisible" :title="$t('ApplyAfterSales.edit')" style="text-align: left;"
               @open="disableScroll" @close="handleDialogClose"
               width="1200">
      <!-- 弹框内容 -->
      <el-form ref="form" :model="formData" :rules="formRules" label-position="top">
        <el-row :gutter="20">

          <el-col :span="12">
            <el-form-item :label=" $t('ApplyAfterSales.materialCode')" prop="materialCode">
              <template #label>
                <span>{{ $t('ApplyAfterSales.materialCode') }}</span>
                <el-tooltip effect="dark">
                  <template v-slot:content>
                    <img :src="snMaterialCodeUrl" alt="Tooltip Image" class="resized-image">
                  </template>
                  <el-icon class="custom-tooltip-icon">
                    <QuestionIcon/>
                  </el-icon>
                </el-tooltip>
              </template>
              <el-input v-model="formData.materialCode"
                        :placeholder="$t('ApplyAfterSales.materialCodePlaceholder')"
                        class="custom-input-width"
                        :rules="formRules.materialCode">
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="SN">
              <template #label>
                <span>SN</span>
                <el-tooltip effect="dark">
                  <template v-slot:content>
                    <img :src="snImgUrl" alt="Tooltip Image" class="resized-image">
                  </template>
                  <el-icon class="custom-tooltip-icon">
                    <QuestionIcon/>
                  </el-icon>
                </el-tooltip>
              </template>
              <el-input v-model="formData.SN" :placeholder="$t('ApplyAfterSales.sNPlaceholder')" :rules="formRules.SN"
                        class="custom-input-width"
                        :disabled="true"></el-input>
            </el-form-item>
          </el-col>

        </el-row>

        <el-row :gutter="20">

          <el-col :span="12">
            <el-form-item label="SN Label" prop="SNFileList" :rules="formRules.SNFileList">
              <el-upload v-model:file-list="formData.SNFileList" class="upload-demo"
                         :before-upload="handleBeforeUploadSN"
                         :limit="1" accept="image/*">
                <el-button class="upload-button">
                  <el-icon>
                    <Upload/>
                  </el-icon>
                  {{ $t('ApplyAfterSales.UploadAttachment') }}
                </el-button>

              </el-upload>
            </el-form-item>

          </el-col>

          <el-col :span="12">

            <el-form-item :label="$t('ApplyAfterSales.invoice')" prop="invoiceFileList">
              <!-- 上传附件的代码，可以使用 el-upload -->
              <el-upload v-model:file-list="formData.invoiceFileList" class="upload-demo"
                         :before-upload="handleBeforeUploadInvoice"
                         :limit="1" accept="image/*"
              >
                <el-button class="upload-button">
                  <el-icon>
                    <Upload/>
                  </el-icon>
                  {{ $t('ApplyAfterSales.UploadAttachment') }}
                </el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">

          <el-col :span="12">
            <el-form-item :label=" $t('ApplyAfterSales.defectiveCategory')" prop="defectCategory">

              <el-tree-select
                  v-model="formData.defectCategory"
                  :data="defectCategoryOptions"
                  :props="{ label: 'label', children: 'children' }"
                  :style="{ width: selectWidth }"
                  :show-checkbox="false"
                  :placeholder="$t('ApplyAfterSales.defectiveCategoryPlaceholder')"
                  :render-after-expand="false"
                  clearable filterable
                  class="custom-input-width"
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">

            <el-form-item :label=" $t('ApplyAfterSales.motorAppearance')" prop="motorAppearance"
                          class="custom-radio-group"
                          :rules="formRules.motorAppearance">
              <el-radio-group v-model="formData.motorAppearance">
                <el-radio :label="1"> {{ $t('ApplyAfterSales.good') }}</el-radio>
                <el-radio :label="0"> {{ $t('ApplyAfterSales.abnormal') }}</el-radio>
                <!-- 添加更多选项 -->
              </el-radio-group>
            </el-form-item>

          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label=" $t('ApplyAfterSales.defectivePicture')" prop="imageFileList">
              <!-- 上传附件的代码，可以使用 el-upload -->
              <el-upload v-model:file-list="formData.imageFileList" class="upload-demo"
                         :before-upload="handleBeforeUploadImg"
                         :limit="1" accept="image/*"
              >
                <el-button class="upload-button">
                  <el-icon>
                    <Upload/>
                  </el-icon>
                  {{ $t('ApplyAfterSales.UploadImage') }}
                </el-button>
              </el-upload>
            </el-form-item>
          </el-col>

          <el-col :span="12">

            <el-form-item :label="$t('ApplyAfterSales.defectiveVideo')" prop="videoFileList">

              <el-upload v-model:file-list="formData.videoFileList" class="upload-demo"
                         :before-upload="handleBeforeUploadVideo"
                         :limit="1" accept="video/*"
              >
                <el-button class="upload-button">
                  <el-icon>
                    <Upload/>
                  </el-icon>
                  {{ $t('ApplyAfterSales.UploadAttachment') }}
                </el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="$t('ApplyAfterSales.defectiveDescription')" prop="defectDescription">
              <el-input type="textarea" v-model="formData.defectDescription" :rules="formRules.defectDescription"
                        rows="2"
                        :placeholder="$t('ApplyAfterSales.defectiveDescriptionPlaceholder')" clearable></el-input>
            </el-form-item>

          </el-col>
        </el-row>

        <!-- 按钮区域 -->
        <div class="dialog-footer" style="margin-top: 20px;">
          <el-button @click="closeFaultDialog" class="custom-reset-button"> {{ $t('ApplyAfterSales.cancel') }}
          </el-button>
          <el-button class="button-background-color" @click="validateBeforeSave"> {{ $t('ApplyAfterSales.ok') }}
          </el-button>
        </div>
      </el-form>

    </el-dialog>
  </div>
</template>

<script>
import {
  deleteAfterSalesDetails,
  //getDefectCategorysData,
  showAfterSalesDetails, updateAfterSalesDetails, uploadFileData,
} from "@/api/api";
import {Upload} from "@element-plus/icons-vue";
import dialogMixin from '@/mixins/dialogMixin';
import QuestionIcon from "@/components/QuestionIcon.vue";
import EmptySlot from "@/components/EmptySlot.vue";

export default {
  components: {EmptySlot, QuestionIcon, Upload},
  mixins: [dialogMixin],
  props: {
    loadData: {
      type: Function,
    },
    tableData: {
      type: Array,
      required: true, // 如果从父组件未传递 tableData，会发出警告
    },
    loading: Boolean, // 加载状态
    showOperationColumn: Boolean, // 加载状态
  },

  data() {
    return {
      invoiceVisible: false,
      defectImageVisible: false,
      snMaterialCodeUrl: require('@/assets/sn-materialcode.png'),
      snImgUrl: require('@/assets/sn.jpg'),
      deleteDialogVisible: false,
      deleteIndex: null,
      faultDialogVisible: false,
      formData: {
        materialCode: '',
        SN: '',
        defectDescription: '',
        SNFileList: [], // 用于存储上传的文件列表
        SNUploadedFile: '',
        videoFileList: [],
        videoUploadedFile: '',
        invoiceFileList: [],
        invoiceUploadedFile: '',
        imageFileList: [],
        imgUploadedFile: '',
        defectCategory: [], // 存储选择的不良类别
        motorAppearance: '',
        // 添加其他字段的初始值
      },
      formRules: {
        SN: [{required: true, message: this.$t('ApplyAfterSales.enterSN'), trigger: 'blur'}],
        SNFileList: [{required: true, message: this.$t('ApplyAfterSales.uploadSNLabel'), trigger: 'blur'}],
        motorAppearance: [{required: true, message: this.$t('ApplyAfterSales.checkMotorAppearance'), trigger: 'blur'}],
        defectDescription: [{
          required: true,
          message: this.$t('ApplyAfterSales.enterDefectDescription'),
          trigger: 'blur'
        }],
      },
      cascadeProps: {
        value: 'value', // 值字段
        label: 'label', // 显示的文本字段
        children: 'children' // 子选项字段
      },
      defectCategoryOptions: [],

      showImageDialog: false,
      imageUrl: '',
      videoDialogVisible: false,
      currentVideoUrl: '',
      invoiceUrl: '',
      defectImageUrl: '',
    }
  },
  watch: {
    '$i18n.locale'() {
      this.fetchDefectCategoryOptions();
    }
  },
  mounted() {
    this.fetchDefectCategoryOptions();
  },
  computed: {
    currentTime() {
      const videoPlayer = this.$refs.videoPlayer;
      return videoPlayer ? this.formatTime(videoPlayer.currentTime) : '0:00';
    },
  },
  methods: {
    // 递归函数来查找对应的 label
    findLabelByValue(options, value) {
      for (const option of options) {
        if (option.value === value) {
          return option.label;
        }
        if (option.children && option.children.length > 0) {
          const foundLabel = this.findLabelByValue(option.children, value);
          if (foundLabel) return foundLabel;
        }
      }
      return null; // 如果没有找到，返回 null
    },

    // formatter 方法
    formatDefectCategory(row, column, cellValue) {
      // 使用递归函数来获取 label
      const label = this.findLabelByValue(this.defectCategoryOptions, cellValue);
      return label || cellValue; // 如果找到了 label，则显示 label，否则显示原始 value
    },
    formatMotorAppearance(row, column, cellValue) {
      return cellValue === 1 ? this.$t('ApplyAfterSales.good') : this.$t('ApplyAfterSales.abnormal');
    },
    async fetchDefectCategoryOptions() {
      try {
        const treeData = this.transformToTree(this.defectCategorysData());
        this.defectCategoryOptions = this.transformDefectCateforysData(treeData);
      } catch (error) {
        console.error(this.$t('ApplyAfterSales.fetchDefectCategoriesFailed'));
      }
    },
    handleDialogClose() {
      this.faultDialogVisible = false;
      this.enableScroll();
    },
    async handleBeforeUploadInvoice(file) {
      // 调用公用文件大小检查函数
      if (!this.checkFileSize.call(this, file)) {
        return false; // 阻止上传
      }
      try {
        // 例如使用 axios 手动上传文件
        const formData = new FormData();
        formData.append('file', file);
        const response = await uploadFileData(formData);
        if (response.code === 0) {
          // 处理成功的响应
          this.$message.success(this.$t('ApplyAfterSales.uploadSuccess'));
          // 假设后端响应包含文件的URL和名称
          const fileData = response;
          this.formData.invoiceFileList.push({
            name: fileData.fileName, // 文件名
            key: fileData.key, // 文件名
            url: fileData.url // 文件访问URL
          });
          // 更新用于提交给后端的 uploadedFile 字符串
          this.formData.invoiceUploadedFile = fileData.key; // 假设 response.key 是文件标识符
        } else {
          // 处理非200的响应
          this.$message.error(this.$t('ApplyAfterSales.uploadFailed'));
        }
      } catch (error) {
        // 处理异常
        this.$message.error(this.$t('ApplyAfterSales.uploadException'));
      }
      return false;

    },
    async handleBeforeUploadSN(file) {
      // 调用公用文件大小检查函数
      if (!this.checkFileSize.call(this, file)) {
        return false; // 阻止上传
      }
      try {
        // 例如使用 axios 手动上传文件
        const formData = new FormData();
        formData.append('file', file);

        const response = await uploadFileData(formData);
        if (response.code === 0) {
          // 处理成功的响应
          this.$message.success(this.$t('ApplyAfterSales.uploadSuccess'));
          // 假设后端响应包含文件的URL和名称
          const fileData = response;
          this.formData.SNFileList.push({
            name: fileData.fileName, // 文件名
            key: fileData.key, // 文件名
            url: fileData.url // 文件访问URL
          });
          // 更新用于提交给后端的 uploadedFile 字符串
          this.formData.SNUploadedFile = fileData.key; // 假设 response.key 是文件标识符
        } else {
          // 处理非200的响应
          this.$message.error(this.$t('ApplyAfterSales.uploadFailed'));
        }
      } catch (error) {
        // 处理异常
        this.$message.error(this.$t('ApplyAfterSales.uploadException'));
      }
      return false;
    },
    async handleBeforeUploadVideo(file) {
      // 调用公用文件大小检查函数
      if (!this.checkFileSize.call(this, file)) {
        return false; // 阻止上传
      }
      try {
        // 例如使用 axios 手动上传文件
        const formData = new FormData();
        formData.append('file', file);

        const response = await uploadFileData(formData);

        if (response.code === 0) {
          // 处理成功的响应
          this.$message.success(this.$t('ApplyAfterSales.uploadSuccess'));
          // 假设后端响应包含文件的URL和名称
          //const fileData = response.data;
          const fileData = response;
          this.formData.videoFileList.push({
            name: fileData.fileName, // 文件名
            key: fileData.key, // 文件名
            url: fileData.url // 文件访问URL
          });
          // 更新用于提交给后端的 uploadedFile 字符串
          this.formData.videoUploadedFile = fileData.key; // 假设 response.key 是文件标识符
          return true; // 返回 true 或不返回任何内容，以允许文件上传
        } else {
          // 处理非200的响应
          this.$message.error(this.$t('ApplyAfterSales.uploadFailed'));
          return false; // 阻止文件上传
        }
      } catch (error) {
        // 处理异常
        this.$message.error(this.$t('ApplyAfterSales.uploadException'));
        return false; // 阻止文件上传
      }
    },
    async handleBeforeUploadImg(file) {
      // 调用公用文件大小检查函数
      if (!this.checkFileSize.call(this, file)) {
        return false; // 阻止上传
      }
      try {
        // 例如使用 axios 手动上传文件
        const formData = new FormData();
        formData.append('file', file);

        const response = await uploadFileData(formData);
        if (response.code === 0) {
          // 处理成功的响应
          this.$message.success(this.$t('ApplyAfterSales.uploadSuccess'));
          const fileData = response;
          this.formData.imageFileList.push({
            name: fileData.fileName, // 文件名
            key: fileData.key,
            url: fileData.url // 文件访问URL
          });
          // 更新用于提交给后端的 uploadedFile 字符串
          this.formData.imgUploadedFile = fileData.key; // 假设 response.key 是文件标识符
        } else {
          // 处理非200的响应
          this.$message.error(this.$t('ApplyAfterSales.uploadFailed'));
        }
      } catch (error) {
        // 处理异常
        this.$message.error(this.$t('ApplyAfterSales.uploadException'));
      }
      return false;

    },
    async editItem(index) {
      this.faultDialogVisible = true;

      try {
        const faultInfoId = index.id;

        const faultInformation = await showAfterSalesDetails(faultInfoId);

        this.formData.fault_id = faultInformation.data.id;

        this.formData.materialCode = faultInformation.data.material_code;
        this.formData.SN = faultInformation.data.sn;

        this.formData.defectCategory = faultInformation.data.error_type;
        this.formData.motorAppearance = faultInformation.data.appearance_status;
        this.formData.defectDescription = faultInformation.data.description;

        // 处理文件URL展示
        const imgfileUrl = faultInformation.data.image_url; // 假设这是你从后端获取到的文件URL
        const imgfileName = faultInformation.data.image_name; // 从URL中提取文件名
        if (imgfileUrl && imgfileName) {
          this.formData.imageFileList = [{
            name: imgfileName, // 文件名
            url: imgfileUrl, // 文件URL
            status: 'success' // 标记为已上传
          }];
        }

        const videofileUrl = faultInformation.data.video_url; // 假设这是你从后端获取到的文件URL
        const videofileName = faultInformation.data.video_name; // 从URL中提取文件名
        if (videofileUrl && videofileName) {
          this.formData.videoFileList = [{
            name: videofileName, // 文件名
            url: videofileUrl, // 文件URL
            status: 'success' // 标记为已上传
          }];
        }
        const SNfileUrl = faultInformation.data.sn_label_url; // 假设这是你从后端获取到的文件URL
        const SNfileName = faultInformation.data.sn_label_name; // 从URL中提取文件名
        if (SNfileUrl && SNfileName) {
          this.formData.SNFileList = [{
            name: SNfileName, // 文件名
            url: SNfileUrl, // 文件URL
            status: 'success' // 标记为已上传
          }];
        }
        const invoiceUrl = faultInformation.data.invoice_url; // 假设这是你从后端获取到的文件URL
        const invoiceName = faultInformation.data.invoice_name; // 从URL中提取文件名
        if (invoiceUrl && invoiceName) {
          this.formData.invoiceFileList = [{
            name: invoiceName, // 文件名
            url: invoiceUrl, // 文件URL
            status: 'success' // 标记为已上传
          }];
        }
      } catch (error) {
        console.error(this.$t('ProductManage.loadDataFailed'));
      }
    },

    closeFaultDialog() {
      this.$refs.form.resetFields();
      this.faultDialogVisible = false; // 取消按钮关闭弹框
    },

    async validateBeforeSave() {
      // 进行表单校验
      await this.$refs.form.validate(async (valid) => {
        if (valid) {
          const fault_id = this.formData.fault_id;
          const formData = {
            material_code: this.formData.materialCode,
            sn: this.formData.SN,
            sn_label: this.formData.SNFileList[0]?.name,
            invoice: this.formData.invoiceFileList[0]?.name,
            error_type: 1,
            appearance_status: this.formData.motorAppearance,
            image: this.formData.imageFileList[0]?.name,
            video: this.formData.videoFileList[0]?.name,
            description: this.formData.defectDescription,
            after_sale_id: this.id,
          };

          try {
            const response = await updateAfterSalesDetails(fault_id, formData);
            if (response.code === 0) {
              this.$message.success(this.$t('ApplyAfterSales.updateSuccess'));
              await this.loadData();
              this.faultDialogVisible = false;
              this.$refs.form.resetFields(); // 重置表单数据

            } else {
              this.$message.error(this.$t('ApplyAfterSales.updateFailed'));
            }
          } catch (error) {
            this.$message.error(this.$t('ApplyAfterSales.updateException'));
          }
        } else {
          this.$message.error(this.$t('ApplyAfterSales.formValidationFailed'));
        }
      });
    },

    async deleteItem(row) {
      try {
        await deleteAfterSalesDetails(row.id);
        await this.loadData();
      } catch (error) {
        // 处理错误，例如输出错误提示或进行其他处理
        console.error(this.$t('ProductManage.deleteDataFailed'));
      }
    },

    cancelDelete() {
      this.deleteDialogVisible = false;
    },
    confirmDelete() {
      // 确保 this.deleteIndex 是有效的索引
      if (this.deleteIndex !== null && this.deleteIndex >= 0 && this.deleteIndex < this.tableData.length) {
        // 创建一个本地的副本数组，避免直接修改父组件的 props
        const localTableData = [...this.tableData];

        // 在本地数组上进行删除操作
        localTableData.splice(this.deleteIndex, 1);
        // 将修改后的数据传递给父组件，可以使用 $emit 触发一个自定义事件
        this.$emit('update:tableData', localTableData);
      }

      //目前用前端删除的方法
      this.deleteIndex = null;
      this.deleteDialogVisible = false;
    },
    showSNLabel(row) {
      this.showImageDialog = true;
      this.imageUrl = row.sn_label_url;
    },
    showInvoice(row) {
      this.invoiceVisible = true;
      this.invoiceUrl = row.invoice_url;
    },
    showDefectImage(row) {
      this.defectImageVisible = true;
      this.defectImageUrl = row.image_url;
    },
    showVideo(row) {
      this.videoDialogVisible = true;
      this.currentVideoUrl = row.video_url;
    },
    formatTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
  },

};
</script>

<style scoped>
:deep(.el-upload-list) {
  width: 500px;
}

:deep(.el-upload-list__item-name) {
  width: 300px;
}

.custom-tooltip-icon {
  margin-left: 8px; /* 在图标和标签文本之间添加一些空间 */
  cursor: pointer; /* 更明确地指示这是一个可以互动的图标 */
  font-size: 14px;
  color: black;
}

:deep( .custom-radio-group .el-radio__input.is-checked .el-radio__inner ) {
  border-color: rgb(0, 154, 68) !important;
  background-color: white !important;
}

:deep( .custom-radio-group .el-radio__input.is-checked .el-radio__inner::after ) {
  background-color: rgb(0, 154, 68) !important;
  width: 8px; /* 增加中间点的宽度 */
  height: 8px; /* 增加中间点的高度 */
  transform: translate(-50%, -50%); /* 确保点居中 */
}

:deep( .custom-radio-group .el-radio__label ) {
  color: rgb(38, 38, 38); /* 黑色 */
}

.custom-dialog-footer-padding .el-dialog__body {
  padding-bottom: 10px; /* 减少弹窗底部内边距，根据需要调整 */
}

:deep( .el-form-item__content ) {
  margin-top: 0; /* 减少内容顶部的外边距 */
}

:deep( .el-form-item__label ) {
  margin-bottom: 0; /* 减少label下方的外边距 */
  padding-bottom: 0; /* 减少label的内边距 */
}

.custom-input-width {
  width: 87%; /* 或其他您希望的宽度 */
}

.table-show {
  /* 样式 */
  margin: -25px 16px 16px 16px;
}

.video-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

/* 可以在这里定义表格的样式 */
</style>
